<template>
    <el-container>
        <el-header>
            <HeaderTop />
        </el-header>
        <el-container>
            <el-aside width="200px">
                <LeftMenu />
            </el-aside>
            <el-container>
                <el-main>
                    <div class='list_content'>
                        <h2 style="text-align: center;">上传图片(不大于1mb)</h2>
                        <div style="padding:50px">
                            <el-upload class="upload-demo" drag action="https://shb.dreamsun.net/apis/upload/imgs" multiple :headers="uploaHeaders" :data="uploadData" :on-success="uploadSUcc">
                                <!-- <el-upload class="upload-demo" drag action="http://127.0.0.1:9090/apis/upload/imgs" multiple :headers="uploaHeaders" :data="uploadData" :on-success="uploadSUcc"> -->
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" slot="tip">只能上传小于1mb的图片</div>
                            </el-upload>
                        </div>
                    </div>

                    <div style=" margin-left:20px;">生成图片链接: <span style="color:red; margin-left:20px;">{{link}}</span>
                        <el-button @click="openLink" style=" margin-left:20px;" type="primary">打开</el-button>
                    </div>
                </el-main>
                <el-footer>
                    <Footer />
                </el-footer>
            </el-container>
        </el-container>
        <router-view /><!-- 路由出口 -->
    </el-container>
</template>

<script>
import LeftMenu from '@/components/LeftMenu0.vue'
import HeaderTop from '@/components/Top.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'UserAdd',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            link: "",
            uploaHeaders: {
                // "Content-Type": "multipart/form-data"
            },
            uploadData: {
                // user_id: 0,
            }
        }
    },

    created() { },

    methods: {
        uploadSUcc(response, file, fileList) {
            // alert(response.Msg)
            console.log(response)
            this.link = response.Data
            alert("上传成功!")
        },
        openLink() {
            window.open(this.link)
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}
.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}
.upload-demo {
    text-align: center;
}
</style>
